import {
  useAppDispatch,
  useAppSelector,
} from '../../../engine/hooks/redux.hooks';
import svgjson from 'svgson';

import { Button, Layout, Skeleton, Table, Tag, theme, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { clearUnit, getUnit, updateUnit } from './Unit.slice';
import CustomForm from '../../../components/CustomForm';
import { UnitType } from '../../../helpers/Unit.interface';
import { colors, statusOption } from '../../../helpers/constants';
const { Content, Sider, Header, Footer } = Layout;

export function UnitDetail({ unitId, expanded, onClose }) {
  const { unit, loading } = useAppSelector((state) => state.unit);

  const dispatch = useAppDispatch();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [loadingSvg, setLoadingSvg] = useState(false);

  useEffect(() => {
    dispatch(getUnit(unitId));
    return () => {};
  }, []);

  useEffect(() => {
    if (expanded) {
      dispatch(getUnit(unitId));
    } else {
      dispatch(clearUnit(unitId));
    }
  }, [expanded]);

  if (unit === null) {
    return <Skeleton active />;
  }

  const {
    id,
    extra: { cellar, attic, garage, balcony, garden, styleFr, styleDe },
    type,
    status,
    info: {
      numberRoom,
      areaLiving,
      areaTotal,
      floor,
      floorDe,
      name,
      orientationDe,
      orientationFr,
      urlDocument,
      price,
      priceSelling,
      priceCharge,
    },
  } = unit;

  const myFunc = (u: UnitType) => {};

  const m2Exposant = (
    <span>
      m<sup>2</sup>
    </span>
  );

  const inputs = [
    {
      parentLabel: 'Status du lot',
      children: [
        {
          label: 'Nom',
          placeholder: 'Nom du lot affiché',
          name: 'info.name',
          capitalize: false,
          value: name,
          type: 'input',
          col: 4,
        },
        {
          label: 'Type',
          name: 'type',
          value: type,
          type: 'select',
          selectContent: [
            { label: 'Location', value: 'renting' },
            { label: 'Vente', value: 'selling' },
          ],
          col: 4,
        },
        {
          label: 'Status',
          name: 'status',
          value: status,
          type: 'select',
          select: 'colorfull',
          selectContent: statusOption,
          col: 4,
        },
      ],
    },
    {
      parentLabel: 'Information',
      children: [
        {
          label: 'Prix de location',
          placeholder: 'Montant du loyer mensuel',
          addonBefore: 'CHF',
          name: 'info.price',
          value: price,
          type: 'input-currency',
          col: 6,
        },
        {
          label: 'Prix de vente',
          placeholder: 'Prix de vente',
          addonBefore: 'CHF',
          name: 'info.priceSelling',
          value: priceSelling,
          type: 'input-currency',
          col: 6,
        },
        {
          label: 'Prix des charges',
          placeholder: 'Montant des charges mensuel',
          name: 'info.priceCharge',
          value: priceCharge,
          addonBefore: 'CHF',
          type: 'input-currency',
          col: 6,
        },
        {
          label: 'Pièces',
          placeholder: 'Nombre de pièces',
          name: 'info.numberRoom',
          value: numberRoom,
          type: 'number',
          col: 6,
        },
        {
          label: 'Étage FR',
          placeholder: 'Numéro de l’étage',
          name: 'info.floor',
          value: floor,
          capitalize: false,
          type: 'input',
          col: 6,
        },
        {
          label: 'Étage DE',
          placeholder: 'Numéro de l’étage',
          name: 'info.floorDe',
          capitalize: false,
          value: floorDe,
          type: 'input',
          col: 6,
        },
        {
          label: 'Orientation FR',
          placeholder: 'Orientation',
          name: 'info.orientationFr',
          value: orientationFr,
          capitalize: false,
          type: 'input',
          col: 6,
        },
        {
          label: 'Orientation DE',
          placeholder: 'Orientation',
          name: 'info.orientationDe',
          capitalize: false,
          value: orientationDe,
          type: 'input',
          col: 6,
        },
        {
          label: 'Surface à vivre',
          placeholder: 'Superficie à vivre',
          name: 'info.areaLiving',
          addonAfter: m2Exposant,
          value: areaLiving,
          type: 'number',
          col: 6,
        },
        {
          label: 'Surface totale',
          placeholder: 'Superficie du bien',
          name: 'info.areaTotal',
          value: areaTotal,
          addonAfter: m2Exposant,

          type: 'number',
          col: 6,
        },
      ],
    },
    {
      parentLabel: 'Extra',
      children: [
        {
          label: 'Style du bien FR',
          placeholder: 'Type de bien',
          name: 'extra.styleFr',
          value: styleFr,
          type: 'input',
          col: 4,
        },
        {
          label: 'Style du bien DE',
          placeholder: 'Type de bien',
          name: 'extra.styleDe',
          value: styleDe,
          type: 'input',
          col: 4,
        },
        {
          label: 'Balcon',
          placeholder: 'Superficie du balcon',
          name: 'extra.balcony',
          addonAfter: m2Exposant,
          value: balcony,
          type: 'number',
          col: 4,
        },
        {
          label: 'Jardin',
          placeholder: 'Superficie du jardin',
          name: 'extra.garden',
          addonAfter: m2Exposant,
          value: garden,
          type: 'number',
          col: 4,
        },
        {
          label: 'Cave',
          placeholder: 'Superficie de la Cave',
          name: 'extra.cellar',
          addonAfter: m2Exposant,
          value: cellar,
          type: 'number',
          col: 4,
        },
        {
          label: 'Grenier',
          placeholder: 'Superficie du grenier',
          name: 'extra.attic',
          value: attic,
          addonAfter: m2Exposant,
          type: 'number',
          col: 4,
        },
        {
          label: 'Garage',
          placeholder: 'Superficie du garage',
          name: 'extra.garage',
          value: garage,
          addonAfter: m2Exposant,
          type: 'number',
          col: 4,
        },
      ],
    },
    {
      children: [
        {
          label: 'Sauvegarder',
          name: 'send',
          value: 'send',
          type: 'submit',
          col: 24,
          align: 'right',
        },
      ],
    },
  ];
  const formatString = (s) => {
    return Number(s.replace(/[^0-9.-]+/g, ''));
  };
  const onSubmitForm = (data) => {
    if (isNaN(data.info.price)) {
      data.info.price = formatString(data.info.price);
    }

    if (isNaN(data.info.priceSelling)) {
      data.info.priceSelling = formatString(data.info.priceSelling);
    }

    if (isNaN(data.info.priceCharge)) {
      data.info.priceCharge = formatString(data.info.priceCharge);
    }

    dispatch(updateUnit({ ...unit, ...data }));
    onClose();
  };
  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          <CustomForm
            dataSource={inputs}
            handleSubmit={(data) => onSubmitForm(data)}
            labelCol={24}
            wrapperCol={24}
            resetOnFinish={undefined}
            refForm={undefined}
            onValuesChange={undefined}
          />
        </>
      )}
    </>
  );
}

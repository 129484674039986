import React from 'react';

export const PORT_BACKEND = ':8080';
export const googleApiKey = '';

export const rootUrlDev = `http://localhost${PORT_BACKEND}/`;
export const rootUrlProd = 'https://nisekhome-demo.herokuapp.com/';

const apisUrl = [
  { url: 'https://nisekhome-demo.herokuapp.com', match: ['demo', 'niseko'] },
  { url: 'https://valoribox.herokuapp.com', match: ['valoribox'] },
];

export const localStorageUserKey = 'nisekome_user';

const matchUrl = (url, match) => {
  return match.some((str) => url.includes(str));
};

export const apiUrl = () => {
  if (process.env.NODE_ENV !== 'production') {
    return `${rootUrlDev}api`;
  } else {
    const currentUrl = window.location.href;
    const matchedUrl = apisUrl.find((api) => matchUrl(currentUrl, api.match));
    if (matchedUrl) {
      return matchedUrl.url + '/api';
    } else {
      alert('No url found, setup the backend');
      return '';
    }
  }
};

export const statusOption = [
  {
    label: () => (
      <span
        className={'item-color-select'}
        style={{
          backgroundColor: colors.available,
        }}
      >
        Disponible
      </span>
    ),
    value: 'available',
  },

  {
    label: () => (
      <span
        className={'item-color-select'}
        style={{
          backgroundColor: colors.reserved,
        }}
      >
        Réservé
      </span>
    ),
    value: 'reserved',
  },
  {
    label: () => (
      <span
        className={'item-color-select'}
        style={{
          backgroundColor: colors.rentedSold,
        }}
      >
        Loué
      </span>
    ),
    value: 'rented',
    color: 'red',
  },

  {
    label: () => (
      <span
        className={'item-color-select'}
        style={{
          backgroundColor: colors.rentedSold,
        }}
      >
        Vendu
      </span>
    ),
    value: 'sold',
  },
];
export const statusOptionSimple = [
  {
    label: 'Disponible',
    value: 'available',
  },
  {
    label: 'Réservé',
    value: 'reserved',
  },
  {
    label: 'Loué',
    value: 'rented',
    color: 'red',
  },
  {
    label: 'Vendu',
    value: 'sold',
  },
];

export const statusUnit = {
  available: 'available',
  rented: 'rented',
  reserved: 'reserved',
};

export const colors = {
  available: '#1890ff',
  reserved: '#0050b3',
  rentedSold: '#bfbfbf',
  primary: '#4F46E5', // Disponible
  secondary: '#E59246', // réservé
  gray: '#A5A5A5', // rented
  blue: '#0a4b67', // rented
  lightgray: '#e0e0e0',
};

export const colorByStatus = (status) => {
  if (status === statusUnit.available) {
    return colors.primary;
  }

  if (status === statusUnit.rented) {
    return colors.gray;
  }

  if (status === statusUnit.reserved) {
    return colors.secondary;
  }
};

export const formatCurrency = (value, withDecorator = true) => {
  if (value === null || value === undefined) {
    return 0;
  }
  const number = value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, "'");

  return `${withDecorator ? 'CHF ' : ''}${number}${withDecorator ? '.-' : ''}`;
};
